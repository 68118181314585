import '../shared/styles/styles.css'
import UserContext from '../lib/context/UserContext'
import firebaseConfig from '../lib/firebase/firebaseconfig'
import { CHARACTERS, User } from '@istvan-kreisz/hnsw-library'
import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { initializeApp, getApps } from 'firebase/app'
import { getAnalytics, isSupported } from 'firebase/analytics'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectDatabaseEmulator, getDatabase } from '@firebase/database'
import { connectFirestoreEmulator, getFirestore } from '@firebase/firestore'
import { connectAuthEmulator } from '@firebase/auth'
import { getAuth } from '@firebase/auth'
import { isUsingEmulator as setupEmulator } from '../lib/utils/utils'
import localFont from '@next/font/local'
import { useFingerprintAndIP } from '../lib/hooks/useFingerprintAndIP'
import useFirebaseSetup from '../lib/hooks/useFirebaseSetup'

const dosis = localFont({
	src: '../shared/fonts/Dosis-VariableFont.woff2',
	variable: '--font-dosis',
	display: 'swap',
	weight: '100 900',
})

export default function App({ Component, pageProps }) {
	const [userState, setUserState] = useState<User | undefined>(undefined)
	const [tempId, setTempId] = useState<string | undefined>(undefined)

	const userRef_ = useRef<User | undefined>(undefined)

	const { setIPIfNeeded, updateFpIfNeeded } = useFingerprintAndIP()

	const { getFunction } = useFirebaseSetup(['functions'])
	const updateUserCountryFunc = getFunction('updateUserCountryGen2')

	const setCurrentUser = () => {
		const user = getAuth().currentUser
		if (user && !userState) {
			setUserState((state) => {
				return {
					...(state || {}),
					id: user.uid,
					email: user.email,
					statistics: {
						allTime: {
							wins: 0,
							bangOns: 0,
							level: 0,
							kingofTheWorldSeconds: 0,
						},
						currentSeason: {
							wins: 0,
							bangOns: 0,
							level: 0,
							kingofTheWorldSeconds: 0,
						},
						tournament: {
							highestScore: 0,
						},
					},
					xp: 0,
					essence: 0,
					characters: [],
					selectedCharacterId: CHARACTERS[0].characterId,
					last5Accuracies: [],
					dontShowInfoGraphic: [],
					isStreamerModeOn: false,
					referral: {
						code: '',
						redeemedCode: '',
						signedUp: [],
						subscribed: [],
					},
					selectedMapStyle: 'Regular',
					unlockedMapStyles: ['Regular'],
					subscribedTo: [],
				}
			})
		}
	}

	const setup = () => {
		if (!getApps().length && typeof window !== 'undefined') {
			const app = initializeApp(firebaseConfig)
			isSupported()
				.then((result) => {
					if (result) {
						getAnalytics(app)
					}
				})
				.catch()

			const functions = getFunctions(app)
			const firestore = getFirestore(app)
			const database = getDatabase(
				app,
				setupEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_DEFAULT_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_DEFAULT_DATABASE_URL_V2
			)
			const chatDatabase = getDatabase(
				app,
				setupEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_CHAT_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_CHAT_DATABASE_URL_V2
			)
			const mmDatabase = getDatabase(
				app,
				setupEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_MM_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_MM_DATABASE_URL_V2
			)
			const auth = getAuth()
			setCurrentUser()

			if (setupEmulator('functions')) {
				connectFunctionsEmulator(functions, 'localhost', 5001)
			}
			if (setupEmulator('firestore')) {
				connectFirestoreEmulator(firestore, 'localhost', 8080)
			}
			if (setupEmulator('database')) {
				connectDatabaseEmulator(database, 'localhost', 9000)
				connectDatabaseEmulator(chatDatabase, 'localhost', 9000)
				connectDatabaseEmulator(mmDatabase, 'localhost', 9000)
			}
			if (setupEmulator('auth')) {
				connectAuthEmulator(auth, 'http://localhost:9100')
			}
		}
	}

	setup()

	const clarifyLastSetUserId = useRef<string>()

	useLayoutEffect(() => {
		setTimeout(() => {
			if (
				typeof window !== 'undefined' &&
				// @ts-ignore
				typeof window?.clarity !== 'undefined' &&
				userState
			) {
				if (userState.id && clarifyLastSetUserId.current !== userState.id) {
					clarifyLastSetUserId.current = userState.id
					// @ts-ignore
					window.clarity('identify', userState.id)
				}
			}
		}, 1000)
		// @ts-ignore
	}, [userState])

	useEffect(() => {
		const setTrackingParams = () => {
			const urlParams = new URLSearchParams(window.location.search)
			const utmSource = urlParams.get('utm_source')
			const utmCampaign = urlParams.get('utm_campaign')

			if (utmSource && utmCampaign) {
				localStorage.setItem('utm_source', utmSource)
				localStorage.setItem('utm_campaign', utmCampaign)
			}
		}

		const setTempIdIfNeeded = () => {
			const tempId = localStorage.getItem('_id')
			if (tempId) {
				setTempId(tempId)
			}
		}

		setTrackingParams()
		setTempIdIfNeeded()
	}, [])

	const didSetupIPandFP = useRef(false)

	useEffect(() => {
		if (
			typeof userState !== 'undefined' &&
			updateUserCountryFunc &&
			didSetupIPandFP.current === false
		) {
			didSetupIPandFP.current = true
			if (userState) {
				if (!userState.country) {
					;(async () => {
						await setIPIfNeeded()
						const country = localStorage.getItem('_c_')
						if (country) {
							await updateUserCountryFunc({
								userId: userState.id,
								country: country,
							})
						}
					})()
				}
			} else {
				updateFpIfNeeded()
				setIPIfNeeded()
			}
		}
	}, [userState, updateUserCountryFunc])

	return (
		<UserContext.Provider
			value={{
				userContext: userState,
				userRef: userRef_,
				setUserContext: (user) => {
					setUserState(user)
					userRef_.current = user
				},
				tempId: tempId,
				setTempId: setTempId,
				getGameId: () => userRef_.current?.id || tempId,
			}}>
			<main className={dosis.variable}>
				<Component {...pageProps} />
			</main>
		</UserContext.Provider>
	)
}
