import {
	PointType,
	Room,
	Player,
	RoomUser,
	Round,
	Guess,
	GameMode,
	WaitlistItem,
	users,
	getRoundInfo,
	playersInCurrentRound as playersInRound,
	seekerGuesses as seekGuesses,
	User,
	CharacterElementType,
	Character,
	getCharacterWithId,
	CharacterRarity,
	CameraPosition,
	Pov,
	MapStyle,
	currentDate,
	RoomMM,
	RoomUserTurnMM,
	RoomUserInfoMM,
	RoomUserDataMM,
	userInfosMM,
	userDataMM,
	RoomInfoMM,
	UserInfoDictionaryMM,
	withoutWolly,
	CompletedChallengesDictionaryMM,
	MovementMode,
	MapColorStyle,
} from '@istvan-kreisz/hnsw-library'
import { assert, create, string, number, Struct, array } from 'superstruct'
import { onValue, set, Database, ref, off, Unsubscribe, get } from 'firebase/database'
import { getColorStylesConfig } from './mapColorStyles'

type Bounds = { north: number; south: number; east: number; west: number }

const toLatLng = (point: PointType): google.maps.LatLng => {
	return new google.maps.LatLng({ lat: point.lat, lng: point.lng })
}

const fromLatLng = (latLng: google.maps.LatLng): PointType => {
	return { lat: latLng.lat(), lng: latLng.lng() }
}

const isRunningLocally =
	typeof location !== 'undefined' &&
	location.hostname === 'localhost' &&
	process.env.NODE_ENV === 'development'

const isEmulator =
	process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_USE_SIMULATOR === 'true'

const isUsingEmulator = (
	service: 'functions' | 'firestore' | 'database' | 'auth' | 'any' = 'any'
): boolean => {
	const isRunningOnEmulator = location.hostname === 'localhost' && isEmulator
	switch (service) {
		case 'functions':
			return (
				process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR_FUNCTIONS === 'true' &&
				isRunningOnEmulator
			)
		case 'firestore':
			return (
				process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR_FIRESTORE === 'true' &&
				isRunningOnEmulator
			)
		case 'database':
			return (
				process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR_DATABASE === 'true' &&
				isRunningOnEmulator
			)
		case 'auth':
			return (
				process.env.NEXT_PUBLIC_FIREBASE_USE_EMULATOR_AUTH === 'true' && isRunningOnEmulator
			)
		default:
			return isRunningLocally
	}
}

type RoomMethods = {
	getRound(): Round
	allUsers(): RoomUser[]
	allRounds(): Round[]
	playersInCurrentRound(): Player[]
	seekerGuesses(userId: string): Guess[]
}

type RoomExtended = Room & RoomMethods

class RoomWithMethods implements RoomMethods {
	room: Room

	constructor(room: Room) {
		this.room = room
		Object.assign(this, room)
	}

	getRound = (): Round => {
		return getRoundInfo(this.room).currentRound
	}

	allUsers = (): RoomUser[] => {
		return withoutWolly(users(this.room))
	}

	allRounds = (): Round[] => {
		return Object.values(this.room.rounds)
	}

	playersInCurrentRound = (): Player[] => {
		return playersInRound(this.room)
	}

	seekerGuesses = (userId: string): Guess[] => {
		return seekGuesses(this.room, userId)
	}
}

const toRoomExtended = (room: Room): RoomExtended => {
	const roomExtended = new RoomWithMethods(room) as Room & RoomWithMethods
	return roomExtended
}

type RoomMethodsMM = {
	topUsers(): RoomUserInfoMM[]
	user(userId: string): RoomUserDataMM | undefined
	currentTurn(userId: string): RoomUserTurnMM | undefined
}

type RoomExtendedMM = RoomMM & RoomMethodsMM

class RoomWithMethodsMM implements RoomMethodsMM {
	room: RoomMM

	constructor(room: RoomMM) {
		this.room = room
		Object.assign(this, room)
	}

	topUsers = (): RoomUserInfoMM[] => {
		return userInfosMM(this.room).filter((user) => user.lives)
	}

	user = (userId: string): RoomUserDataMM | undefined => {
		return userDataMM(this.room, userId)
	}

	currentTurn = (userId: string): RoomUserTurnMM | undefined => {
		return userDataMM(this.room, userId).turn
	}
}

const toRoomExtendedMM = (room: RoomMM): RoomExtendedMM => {
	const roomExtended = new RoomWithMethodsMM(room) as RoomMM & RoomWithMethodsMM
	return roomExtended
}

const setupServerTimeOffsetListener = (
	database: Database,
	updated: (room: number) => void
): Unsubscribe => {
	const offsetRef = ref(database, '.info/serverTimeOffset')
	return onValue(offsetRef, (snap) => {
		const offset = snap.val()
		updated(offset)
	})
}

const setupRoomListener = (
	database: Database,
	roomId: string,
	updated: (room: RoomExtended) => void
): Unsubscribe => {
	if (!database) return
	const roomRef = ref(database, ['rooms', roomId].join('/'))
	return onValue(roomRef, (snapshot) => {
		try {
			const val = { ...snapshot.val(), ...{ id: snapshot.key } }
			const room = create(val, Room)
			const roomExtended = toRoomExtended(room)
			updated(roomExtended)
		} catch (err) {
			if (isUsingEmulator()) {
				console.log(err)
			}
		}
	})
}

const getRoomInfoMM = async (database: Database, roomId: string): Promise<RoomInfoMM> => {
	if (!database) throw new Error()

	const roomRef = ref(database, ['roomsMM', roomId, 'roomInfo'].join('/'))
	const snapshot = await get(roomRef)
	const roomInfo = create(snapshot.val(), RoomInfoMM)
	return roomInfo
}

const setupListener = <T, S>(
	database: Database,
	reference: string[],
	type: Struct<T, S>,
	completion: (value: T | undefined) => void
) => {
	if (!database) return
	const dbRef = ref(database, reference.join('/'))

	return onValue(dbRef, (snapshot) => {
		try {
			const val = snapshot.val()
			if (val) {
				const typeCastedValue = create(val, type)
				completion(typeCastedValue)
			} else {
				completion(undefined)
			}
		} catch (err) {
			if (isUsingEmulator()) {
				console.log(err)
			}
		}
	})
}

const setupAcivePlayersListener = (
	database: Database,
	completion: (user: number | undefined) => void
) => {
	return setupListener(database, ['activePlayersMM'], number(), completion)
}

const setupUserCountListener = (
	database: Database,
	roomId: string,
	completion: (user: number | undefined) => void
) => {
	return setupListener(database, ['roomsMM', roomId, 'userCount'], number(), completion)
}

const setupUserInfoListener = (
	database: Database,
	roomId: string,
	completion: (userInfo: UserInfoDictionaryMM) => void
) => {
	return setupListener(
		database,
		['roomsMM', roomId, 'userInfo'],
		UserInfoDictionaryMM,
		completion
	)
}

const setupUserListener = (
	database: Database,
	roomId: string,
	userId: string,
	completion: (user: RoomUserDataMM | undefined) => void
) => {
	return setupListener(
		database,
		['roomsMM', roomId, 'userData', userId],
		RoomUserDataMM,
		completion
	)
}

const setupChallengeBroadcastListener = (
	database: Database,
	roomId: string,
	completion: (completedChallenges: CompletedChallengesDictionaryMM) => void
) => {
	return setupListener(
		database,
		['roomsMM', roomId, 'completedChallenges'],
		CompletedChallengesDictionaryMM,
		completion
	)
}

const waitlistName = (gameMode: GameMode) => {
	return `waitlist_${gameMode}`
}

const setupWaitlistListener = (
	database: Database,
	gameMode: GameMode,
	userId: string,
	updated: (room: WaitlistItem) => void
): Unsubscribe => {
	if (!database) return
	const waitlistItemRef = ref(database, [waitlistName(gameMode), userId].join('/'))
	return onValue(waitlistItemRef, (snapshot) => {
		try {
			const val = { ...snapshot.val(), ...{ id: snapshot.key } }
			const waitlistItem = create(val, WaitlistItem)
			updated(waitlistItem)
		} catch (err) {
			if (isUsingEmulator()) {
				console.log(err)
			}
		}
	})
}

const setupPlayerMovesListener = (
	database: Database,
	targetUserId: string,
	updated: (cameraPosition: CameraPosition | undefined) => void
): Unsubscribe => {
	return setupListener(database, ['playerMoves', targetUserId], CameraPosition, updated)
}

const cancelListener = (database: Database, path: string[]) => {
	if (!database) return
	const reference = ref(database, path.join('/'))
	off(reference)
}

const cancelServerTimeListener = (database: Database) => {
	cancelListener(database, ['.info', 'serverTimeOffset'])
}

const cancelRoomListener = (database: Database, roomId: string) => {
	cancelListener(database, ['rooms', roomId])
}

const cancelWaitlistListener = (database: Database, gameMode: GameMode, userId: string) => {
	cancelListener(database, [waitlistName(gameMode), userId])
}

const cancelPlayerMovesListener = (database: Database, targetUserId: string) => {
	cancelListener(database, ['playerMoves', targetUserId])
}

const cancelActivePlayersListener = (database: Database) => {
	cancelListener(database, ['activePlayersMM'])
}

const cancelUserCountListener = (database: Database, roomId: string) => {
	cancelListener(database, ['roomsMM', roomId, 'userCount'])
}

const cancelUserInfoListener = (database: Database, roomId: string) => {
	cancelListener(database, ['roomsMM', roomId, 'userInfo'])
}

const cancelUserListener = (database: Database, roomId: string, userId: string) => {
	cancelListener(database, ['roomsMM', roomId, 'userData', userId])
}

const cancelChallengeBroadcastListener = (database: Database, roomId: string) => {
	cancelListener(database, ['roomsMM', roomId, 'completedChallenges'])
}

const compareArrays = <T>(array1: Array<T>, array2: Array<T>): boolean => {
	return (
		array1.length === array2.length && array1.every((value, index) => value === array2[index])
	)
}

const special = [
	'zeroth',
	'first',
	'second',
	'third',
	'fourth',
	'fifth',
	'sixth',
	'seventh',
	'eighth',
	'ninth',
	'tenth',
	'eleventh',
	'twelfth',
	'thirteenth',
	'fourteenth',
	'fifteenth',
	'sixteenth',
	'seventeenth',
	'eighteenth',
	'nineteenth',
]
const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']

const stringifyNumber = (n) => {
	if (n < 20) return special[n]
	if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth'
	return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10]
}

const makeCancelable = <T>(promise: Promise<T>): { promise: Promise<T>; cancel: () => void } => {
	let hasCanceled_ = false

	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise.then(
			(val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
			(error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
		)
	})

	return {
		promise: wrappedPromise,
		cancel() {
			hasCanceled_ = true
		},
	}
}

const mapOptions = (
	extraFields: google.maps.MapOptions = {},
	mapStyle: MapStyle,
	mapColorStyle: MapColorStyle,
	bounds?: Bounds
): google.maps.MapOptions => {
	const noLabelsStyle: google.maps.MapTypeStyle[] = [
		{
			elementType: 'labels',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	]
	const noBordersSyle: google.maps.MapTypeStyle[] = [
		{
			featureType: 'administrative.country',
			elementType: 'geometry.stroke',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative.province',
			elementType: 'geometry.stroke',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	]
	let mapStyles = []
	if (mapStyle === 'No Labels') {
		mapStyles = noLabelsStyle
	} else if (mapStyle === 'No Labels, No Borders') {
		mapStyles = [...noBordersSyle, ...noLabelsStyle]
	}
	if (mapColorStyle && mapColorStyle !== 'Regular') {
		mapStyles = [...mapStyles, ...getColorStylesConfig(mapColorStyle)]
	}

	return typeof google !== 'undefined'
		? {
				zoom: 2,
				minZoom: 1,
				...(!bounds && {
					zoom: 2,
					center: {
						lat: 0,
						lng: 0,
					},
				}),
				// restriction: {
				// 	latLngBounds: { north: 90, south: -90, west: -179.99, east: 179.99 },
				// 	strictBounds: false,
				// },
				draggableCursor: 'auto',
				streetViewControl: false,
				clickableIcons: false,
				mapTypeControl: false,
				keyboardShortcuts: false,
				fullscreenControl: false,
				zoomControl: true,
				rotateControl: false,
				styles: mapStyles,
				...extraFields,
		  }
		: {}
}

const streetViewOptions = (
	extraFields: google.maps.StreetViewPanoramaOptions = {}
): google.maps.StreetViewPanoramaOptions =>
	typeof google !== 'undefined'
		? {
				pov: {
					heading: 34,
					pitch: 10,
				},
				showRoadLabels: false,
				addressControl: false,
				zoomControl: false,
				panControl: false,
				fullscreenControl: false,
				motionTracking: false,
				motionTrackingControl: false,
				linksControl: true,
				clickToGo: true,
				scrollwheel: true,
				disableDoubleClickZoom: false,
				...extraFields,
		  }
		: {}

const capitalizeFirstLetter = (toCapitalize?: string) => {
	return toCapitalize?.charAt(0)?.toUpperCase() + toCapitalize?.slice(1)
}

const numberWithCommas = (num: number) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const isSubscriber = (user: User | undefined): boolean => {
	return user?.subscriptionStatus === 'active' && !!user?.membership
}

const elementBackgrounColor = (
	elementType: CharacterElementType | undefined,
	withAlphaComponent: boolean
): string => {
	switch (elementType) {
		case 'fire':
			return `rgb(238,138,138,${withAlphaComponent ? '0.7' : '1.0'})`
		case 'grass':
			return `rgb(69,226,169,${withAlphaComponent ? '0.3' : '1.0'})`
		case 'ground':
			return `rgb(249,180,105,${withAlphaComponent ? '0.3' : '1.0'})`
		case 'ice':
			return `rgb(153,218,227,${withAlphaComponent ? '0.7' : '1.0'})`
		case 'rock':
			return `rgb(153,153,153,${withAlphaComponent ? '0.4' : '1.0'})`
		case 'water':
			return `rgb(127,189,217,${withAlphaComponent ? '0.5' : '1.0'})`
		default:
			return `rgb(223,223,223,1)`
	}
}

const elementMarkerLineColor = (elementType: CharacterElementType | undefined): string => {
	switch (elementType) {
		case 'fire':
			return `#E75858`
		case 'grass':
			return `#16DB93`
		case 'ground':
			return `#F9B469`
		case 'ice':
			return `#00A3B8`
		case 'rock':
			return `#999999`
		case 'water':
			return `#007CB3`
		default:
			return `rgb(223,223,223)`
	}
}

const spiritBackground = (
	spirit: Character | undefined,
	withAlphaComponent: boolean
): { background: string } => {
	let inputStyle = {
		background: 'radial-gradient(circle, rgb(50,67,87,1) 0%, rgb(35,91,144,1) 100%)',
	}

	if (spirit?.typetags.length === 1)
		inputStyle = {
			background:
				'radial-gradient(circle, rgb(255,255,255,1) 0%,' +
				elementBackgrounColor(spirit.typetags[0], withAlphaComponent) +
				' 100%)',
		}
	else {
		inputStyle = {
			background:
				'radial-gradient(circle, ' +
				elementBackgrounColor(spirit?.typetags[0], withAlphaComponent) +
				' 0%, ' +
				elementBackgrounColor(spirit?.typetags[1], withAlphaComponent) +
				' 100%)',
		}
	}
	return inputStyle
}

const userBackgroundColors = (
	characterId: string | undefined,
	withAlphaComponent: boolean
): string[] => {
	const character: Character = getCharacterWithId(characterId)
	return character?.typetags.map((type) => elementBackgrounColor(type, withAlphaComponent)) || []
}

const userMarkerLineColors = (characterId: string | undefined): string[] => {
	const character: Character = getCharacterWithId(characterId)
	return character?.typetags.map((type) => elementMarkerLineColor(type)) || []
}

const userBackground = (
	characterId: string | undefined,
	withAlphaComponent: boolean
): { background: string } => {
	const character: Character = getCharacterWithId(characterId)
	return spiritBackground(character, withAlphaComponent)
}

const userBorderColor = (characterId: string | undefined, withAlphaComponent: boolean): {} => {
	const character: Character = getCharacterWithId(characterId)
	const borderColor = {
		border: '2px solid ' + elementBackgrounColor(character.typetags[0], withAlphaComponent),
	}
	return borderColor
}

const isSpiritUnlocked = (characterId: string, user: User | undefined): boolean => {
	return !!user?.characters.find((char) => char.characterId === characterId)
}

const getCharacterSVG = (characterId?: string): string => {
	return characterId ? `/s/${characterId}.svg` : ''
}

const getCharacterLevel = (user: User, characterId: string) => {
	return user?.characters?.find((x) => x.characterId === characterId)?.level + 1
}

const rarityColors = (rarity: CharacterRarity) => {
	switch (rarity) {
		case 'common':
			return 'bg-gray-200'
		case 'rare':
			return 'bg-lightblue text-white'
		case 'super rare':
			return 'bg-purple text-white'
		case 'legendary':
			return 'bg-orange text-white'
	}
}

const rarityStars = (rarity: CharacterRarity) => {
	switch (rarity) {
		case 'common':
			return 1
		case 'rare':
			return 2
		case 'super rare':
			return 3
		case 'legendary':
			return 4
	}
}

const typeTagColors = (elementType: CharacterElementType) => {
	switch (elementType) {
		case 'water':
			return 'bg-medblue/50'
		case 'ground':
			return 'bg-brown'
		case 'ice':
			return 'bg-aquablue/40'
		case 'rock':
			return 'bg-gray-400'
		case 'grass':
			return 'bg-aquagreen/80'
		case 'fire':
			return 'bg-redmascot/60'
		default:
			return 'bg-gray-300'
	}
}

const getRandomArrayElements = <T>(array: T[], numberOfElements: number): T[] => {
	const shuffled = array.sort(() => 0.5 - Math.random())
	return shuffled.slice(0, numberOfElements)
}

const referralLink = (user: User) => {
	return `${
		isEmulator ? 'http://localhost:3000' : 'https://hideandseek.world'
	}/signup?referralCode=${user?.referral.code}`
}

const updatePlayerMoves = async (
	database: Database,
	userId: string,
	targetUserId: string,
	location: PointType,
	pov: Pov,
	zoom: number
) => {
	try {
		const basePath = `playerMoves/${userId}/`
		const playerMovesRef = ref(database, basePath)
		assert(targetUserId, string())
		assert(location, PointType)
		assert(pov, Pov)
		assert(zoom, number())

		const newValue: CameraPosition = {
			targetUserId: targetUserId,
			location: location,
			pov: pov,
			zoom: zoom,
			updated: currentDate(),
		}
		await set(playerMovesRef, newValue)
	} catch (err) {
		// console.error(err)
	}
}

const isUserHiding = (room: RoomExtended, userId: string): boolean => {
	return room?.turn.hiderId === userId
}

const isSeekTurn = (room: RoomExtended): boolean => {
	return room?.turn.state === 'seek'
}

const showSeekView = (room: RoomExtended, userId: string, didFinishTurn: boolean) => {
	const isSeekTurn = room?.turn.state === 'seek'
	const userHiding = isUserHiding(room, userId)
	return isSeekTurn && ((userHiding && !!room.hostId) || !didFinishTurn)
}

const roundTo1Decimal = (num: number) => {
	return Math.round((num + Number.EPSILON) * 10) / 10
}

const roundTo2Decimals = (num: number) => {
	return Math.round((num + Number.EPSILON) * 100) / 100
}

const MAX_PLAYER_COUNT_SHOWN_MM = 15

const wait = async (seconds: number) => {
	return new Promise<void>((resolve) => {
		setTimeout(async () => {
			resolve()
		}, seconds * 1000)
	})
}

const retryWithDelayIfFailed = async <T>(block: () => Promise<T>): Promise<T> => {
	try {
		const result = await block()
		return result
	} catch {
		wait(0.5)
		const result = await block()
		return result
	}
}

const movementModeLabel = (movementMode: MovementMode) =>
	movementMode === 'No Move, No Zoom, No Pan' ? 'NMPZ' : movementMode

const PRO_MONTHLY_PRICE = 3
const PREMIUM_MONTHLY_PRICE = 5
const PRO_ANNUAL_PRICE = 24
const PREMIUM_ANNUAL_PRICE = 36

export {
	toLatLng,
	fromLatLng,
	isEmulator,
	isUsingEmulator,
	toRoomExtended,
	toRoomExtendedMM,
	setupServerTimeOffsetListener,
	setupRoomListener,
	getRoomInfoMM,
	setupAcivePlayersListener,
	setupUserCountListener,
	setupUserInfoListener,
	setupUserListener,
	setupChallengeBroadcastListener,
	setupPlayerMovesListener,
	waitlistName,
	setupWaitlistListener,
	cancelServerTimeListener,
	cancelRoomListener,
	cancelWaitlistListener,
	cancelPlayerMovesListener,
	cancelActivePlayersListener,
	cancelUserCountListener,
	cancelUserInfoListener,
	cancelUserListener,
	cancelChallengeBroadcastListener,
	compareArrays,
	stringifyNumber,
	makeCancelable,
	mapOptions,
	streetViewOptions,
	capitalizeFirstLetter,
	numberWithCommas,
	isSubscriber,
	elementBackgrounColor,
	spiritBackground,
	userBackgroundColors,
	userMarkerLineColors,
	userBackground,
	userBorderColor,
	isSpiritUnlocked,
	getCharacterSVG,
	getCharacterLevel,
	rarityStars,
	rarityColors,
	typeTagColors,
	getRandomArrayElements,
	referralLink,
	updatePlayerMoves,
	isUserHiding,
	isSeekTurn,
	showSeekView,
	roundTo1Decimal,
	roundTo2Decimals,
	retryWithDelayIfFailed,
	MAX_PLAYER_COUNT_SHOWN_MM,
	isRunningLocally,
	movementModeLabel,
	PRO_MONTHLY_PRICE,
	PREMIUM_MONTHLY_PRICE,
	PRO_ANNUAL_PRICE,
	PREMIUM_ANNUAL_PRICE,
}

export type { Bounds, RoomExtended, RoomExtendedMM }
