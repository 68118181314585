import { useEffect, useState } from 'react'
import { Functions, getFunctions, httpsCallable } from '@firebase/functions'
import { Database, getDatabase } from '@firebase/database'
import { Firestore, getFirestore } from '@firebase/firestore'
import { Analytics, getAnalytics, isSupported } from '@firebase/analytics'
import { Auth, getAuth } from '@firebase/auth'
import { getApp, getApps } from '@firebase/app'
import { isUsingEmulator } from '../utils/utils'

type FirebaseService = 'functions' | 'database' | 'firestore' | 'analytics' | 'auth'

const useFirebaseSetup = (
	services: FirebaseService[],
	databaseType: 'chat' | 'mm' | 'default' = 'default'
) => {
	const [functions, setfunctions] = useState<Functions>()
	const [database, setdatabase] = useState<Database>()
	const [firestore, setfirestore] = useState<Firestore>()
	const [analytics, setanalytics] = useState<Analytics>()
	const [auth, setauth] = useState<Auth>()

	useEffect(() => {
		if (!getApps().length) return
		const app = getApp()

		if (services.includes('functions')) {
			const functions = getFunctions(app)
			setfunctions(functions)
		}
		if (services.includes('database')) {
			let dbURL: string
			if (databaseType === 'chat') {
				dbURL = isUsingEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_CHAT_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_CHAT_DATABASE_URL_V2
			} else if (databaseType === 'mm') {
				dbURL = isUsingEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_MM_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_MM_DATABASE_URL_V2
			} else {
				dbURL = isUsingEmulator('database')
					? process.env.NEXT_PUBLIC_FIREBASE_DEFAULT_DATABASE_URL_V2_EMU
					: process.env.NEXT_PUBLIC_FIREBASE_DEFAULT_DATABASE_URL_V2
			}
			const database = getDatabase(app, dbURL)
			setdatabase(database)
		}
		if (services.includes('firestore')) {
			const firestore = getFirestore(app)
			setfirestore(firestore)
		}
		if (services.includes('analytics')) {
			isSupported()
				.then((result) => {
					if (result) {
						const analytics = getAnalytics(app)
						setanalytics(analytics)
					}
				})
				.catch()
		}
		if (services.includes('auth')) {
			const auth = getAuth(app)
			setauth(auth)
		}
	}, [])

	const getFunction = (name: string) => {
		if (functions) {
			return httpsCallable(functions, name)
		} else {
			return undefined
		}
	}

	return {
		functions,
		database,
		firestore,
		analytics,
		auth,
		getFunction,
	}
}

export default useFirebaseSetup
export type { FirebaseService }
