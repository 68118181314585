import { MapColorStyle } from '@istvan-kreisz/hnsw-library'

const getColorStylesConfig = (colorStyle: MapColorStyle): google.maps.MapTypeStyle[] => {
	switch (colorStyle) {
		case "Assassin's Creed IV":
			return [
				// {
				// 	featureType: 'all',
				// 	elementType: 'all',
				// 	stylers: [
				// 		{
				// 			visibility: 'on',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'all',
				// 	elementType: 'labels',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 		{
				// 			saturation: '-100',
				// 		},
				// 	],
				// },
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							saturation: 36,
						},
						{
							color: '#000000',
						},
						{
							lightness: 40,
						},
						// {
						// 	visibility: 'off',
						// },
					],
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						// {
						// 	visibility: 'off',
						// },
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				// {
				// 	featureType: 'all',
				// 	elementType: 'labels.icon',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'administrative',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 17,
						},
						{
							weight: 1.2,
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#4d6059',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#4d6059',
						},
					],
				},
				{
					featureType: 'landscape.natural',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#4d6059',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							lightness: 21,
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#4d6059',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#4d6059',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						// {
						// 	visibility: 'on',
						// },
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#7f8d89',
						},
						{
							lightness: 17,
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#7f8d89',
						},
						{
							lightness: 29,
						},
						{
							weight: 0.2,
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 18,
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#7f8d89',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 19,
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'all',
					stylers: [
						{
							color: '#2b3638',
						},
						{
							visibility: 'on',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#2b3638',
						},
						{
							lightness: 17,
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#24282b',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#24282b',
						},
					],
				},
				// {
				// 	featureType: 'water',
				// 	elementType: 'labels',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'water',
				// 	elementType: 'labels.text',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'water',
				// 	elementType: 'labels.text.fill',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'water',
				// 	elementType: 'labels.text.stroke',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'water',
				// 	elementType: 'labels.icon',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
			]
		case 'Bluish':
			return [
				{
					stylers: [
						{
							hue: '#007fff',
						},
						{
							saturation: 89,
						},
					],
				},
				{
					featureType: 'water',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
			]

		case 'Icy Blue':
			return [
				{
					stylers: [
						{
							hue: '#2c3e50',
						},
						{
							saturation: 250,
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{
							lightness: 50,
						},
						{
							visibility: 'simplified',
						},
					],
				},
				// {
				// 	featureType: 'road',
				// 	elementType: 'labels',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
			]
		case 'Midnight Commander':
			return [
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 13,
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#144b53',
						},
						{
							lightness: 14,
						},
						{
							weight: 1.4,
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'all',
					stylers: [
						{
							color: '#08304b',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							color: '#0c4152',
						},
						{
							lightness: 5,
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#0b434f',
						},
						{
							lightness: 25,
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#0b3d51',
						},
						{
							lightness: 16,
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'all',
					stylers: [
						{
							color: '#146474',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'all',
					stylers: [
						{
							color: '#021019',
						},
					],
				},
			]
		case 'Mondrian':
			return [
				// {
				// 	elementType: 'labels',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'road',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#0F0919',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#E4F7F7',
						},
					],
				},
				// {
				// 	elementType: 'geometry.stroke',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'poi.park',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#002FA7',
						},
					],
				},
				{
					featureType: 'poi.attraction',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#E60003',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#FBFCF4',
						},
					],
				},
				{
					featureType: 'poi.business',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#FFED00',
						},
					],
				},
				{
					featureType: 'poi.government',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#D41C1D',
						},
					],
				},
				{
					featureType: 'poi.school',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#BF0000',
						},
					],
				},
				{
					featureType: 'transit.line',
					elementType: 'geometry.fill',
					stylers: [
						{
							saturation: -100,
						},
					],
				},
			]
		case 'Multi Brand Network':
			return [
				// {
				// 	featureType: 'all',
				// 	elementType: 'labels',
				// 	stylers: [
				// 		{
				// 			visibility: 'on',
				// 		},
				// 	],
				// },
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							saturation: 36,
						},
						{
							color: '#000000',
						},
						{
							lightness: 40,
						},
					],
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						// {
						// 	visibility: 'on',
						// },
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				// {
				// 	featureType: 'all',
				// 	elementType: 'labels.icon',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'administrative',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 17,
						},
						{
							weight: 1.2,
						},
					],
				},
				{
					featureType: 'administrative.country',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#e5c163',
						},
					],
				},
				{
					featureType: 'administrative.locality',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#c4c4c4',
						},
					],
				},
				{
					featureType: 'administrative.neighborhood',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#e5c163',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 21,
						},
						// {
						// 	visibility: 'on',
						// },
					],
				},
				// {
				// 	featureType: 'poi.business',
				// 	elementType: 'geometry',
				// 	stylers: [
				// 		{
				// 			visibility: 'on',
				// 		},
				// 	],
				// },
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#e5c163',
						},
						{
							lightness: '0',
						},
					],
				},
				// {
				// 	featureType: 'road.highway',
				// 	elementType: 'geometry.stroke',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'road.highway',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#e5c163',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 18,
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#575757',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#2c2c2c',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#999999',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 19,
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 17,
						},
					],
				},
			]
		case 'Regular':
			return []
		case 'Sin City':
			return [
				{
					featureType: 'all',
					elementType: 'labels.text.fill',
					stylers: [
						{
							saturation: 36,
						},
						{
							color: '#000000',
						},
						{
							lightness: 40,
						},
					],
				},
				{
					featureType: 'all',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							visibility: 'on',
						},
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				// {
				// 	featureType: 'all',
				// 	elementType: 'labels.icon',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'administrative',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				{
					featureType: 'administrative',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 17,
						},
						{
							weight: 1.2,
						},
					],
				},
				{
					featureType: 'administrative.locality',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#c4c4c4',
						},
					],
				},
				{
					featureType: 'administrative.neighborhood',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#707070',
						},
					],
				},
				{
					featureType: 'landscape',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 20,
						},
					],
				},
				// {
				// 	featureType: 'poi',
				// 	elementType: 'geometry',
				// 	stylers: [
				// 		{
				// 			color: '#000000',
				// 		},
				// 		{
				// 			lightness: 21,
				// 		},
				// 		{
				// 			visibility: 'on',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'poi.business',
				// 	elementType: 'geometry',
				// 	stylers: [
				// 		{
				// 			visibility: 'on',
				// 		},
				// 	],
				// },
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#be2026',
						},
						{
							lightness: '0',
						},
						// {
						// 	visibility: 'on',
						// },
					],
				},
				// {
				// 	featureType: 'road.highway',
				// 	elementType: 'geometry.stroke',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				// {
				// 	featureType: 'road.highway',
				// 	elementType: 'labels.text.fill',
				// 	stylers: [
				// 		{
				// 			visibility: 'off',
				// 		},
				// 	],
				// },
				{
					featureType: 'road.highway',
					elementType: 'labels.text.stroke',
					stylers: [
						// {
						// 	visibility: 'off',
						// },
						{
							hue: '#ff000a',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 18,
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#575757',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#2c2c2c',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 16,
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#999999',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'labels.text.stroke',
					stylers: [
						{
							saturation: '-52',
						},
					],
				},
				{
					featureType: 'transit',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 19,
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#000000',
						},
						{
							lightness: 17,
						},
					],
				},
			]
	}
}

export { getColorStylesConfig }
