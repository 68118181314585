import { Dispatch, SetStateAction, MutableRefObject, createContext } from 'react'
import { User } from '@istvan-kreisz/hnsw-library'

const UserContext = createContext<{
	userContext: User | undefined | null
	userRef: MutableRefObject<User | undefined | null>
	setUserContext: (user: User | undefined | null) => void
	tempId: string | undefined | null
	setTempId: Dispatch<SetStateAction<string | undefined | null>>
	getGameId: () => string | undefined
}>({
	userContext: undefined,
	userRef: undefined,
	setUserContext: undefined,
	tempId: undefined,
	setTempId: undefined,
	getGameId: () => undefined,
})

export default UserContext
